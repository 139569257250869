* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --primary-colorHover: rgb(224, 0, 19);
  --primary-color: #e33745;
  --secondary-color: #f6f5f5;
  --white: #ffff;

  /* Background color */
  --primary-bg: #ffffff;
  --secondary-bg: #f6f5f5;
  --light-bg: #d9d9d9;
  --blue-bg: #1453f3;
  --lightBlue-bg: #0d99ff;
  --grey-bg: #666666;
  --lightGreen-bg: #dbffcc;
  --lightRed-bg: #ff000096;
  --red-bg: var(--primary-color);
  --lightBlack-bg: #464444;
  --lightGrey-bg: #efefef;
  --greenpistachio-bg: #e6ffdd;
  --pending-bg: #ffd258;
  --green-bg: #509d30;
  --darkGreen-bg: #448d25;
  --fadeGreen-bg: #7fd4ac;
  --fadeBrown-bg: #cbc1a3;
  --lightPink-bg: #e2b39e;
  --pink-bg: #f6989b;
  --pale-yellow-bg: #f0c868;

  /* Text colors */
  --primary-text: #000000;
  --secondary-text: #333333;
  --light-black: #212121;
  --white-text: #ffffff;
  --light-text: #666666;
  --fade-text: #999999;
  --red-text: var(--primary-color);
  --grey-text: #7a7a7a;
  --brown-text: #552b00;

  /* Border Color */
  --light-border: #eeeeee;
  --blue-grey: #a098ae;

  /* Error Color */
  --error-text: #ff0000;
  --light-red: #ff8682;
  --offline-table: #0688ff;
  --notes-back: #f3f3f3;
  --reserved-table: #ff0000;
  --yellow-table: #ffff00;
  --running-table: #99e699;
  --waling-table: #99e699;
  --below-book: #f4fff0;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f6f5f5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 6px;
}

.App {
  font-family: "Poppins", sans-serif !important;
  user-select: none !important;
}

.modal-dialog button:active,
.modal-dialog button:focus-visible {
  background-color: transparent !important;
}
.stickyHeaderClss {
  position: sticky;
  top: 0px;
  background-color: var(--white);
  z-index: 50;
}
.stickyTableheight,
.stickyTableheightStff,
.stickyTableheightCreateDesign,
.stickyTableheightAmenity,
.stickyTableheighCity,
.stickyTableheigh210,
.stickyTableheigh265 {
  height: calc(100vh - 290px);
  overflow: auto;
}

.stickyTableheightStff {
  height: calc(100vh - 330px) !important;
}
.stickyTableheightCreateDesign {
  height: calc(100vh - 200px) !important;
}
.stickyTableheightAmenity {
  height: calc(100vh - 150px) !important;
}
.stickyTableheighCity {
  height: calc(100vh - 170px) !important;
}
.stickyTableheigh210 {
  height: calc(100vh - 240px) !important;
}
.stickyTableheigh265 {
  height: calc(100vh - 265px) !important;
}
